import React, {createRef, useEffect, useState} from 'react';
import {CircularProgress, Grid, Typography} from '@mui/material';
import {DateRange, FiberManualRecord} from '@mui/icons-material';
import {Pie} from 'react-chartjs-2';
import Number from '../../utils/number';

const GraficoPeriodoDia = ({campanha, periodos, valorTotal, titulo, unidadeMedida = '', loading}) => {
  const periodoRef = createRef();

  const [valorAjuste, setValorAjuste] = useState(0);
  const [periodoConfig, setPeriodoConfig] = useState({});

  useEffect(() => {
    setValorAjuste(0);
    setPeriodoConfig({});
    if (!periodoRef.current || !periodos) return;

    const total = periodos?.map(a => parseInt(a.valor || 0)).reduce((a, b) => a + b, 0);
    let ajuste = parseInt(Math.max(((Number.safeParseFloat(valorTotal) - total) / (periodos.length || 1)), 0))
    setValorAjuste(ajuste);

    setPeriodoConfig({
      data: periodos?.map(a => parseInt(a.valor || 0) + ajuste),
      backgroundColor: [
        '#26323829',
        '#007BE166',
        '#007BE1',
        '#263238'
      ],
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campanha, periodos]);

  const formatPeriodo = (index) => {
    if (!periodos?.length) return `0 ${unidadeMedida}`;
    const value = periodos[index]?.valor;
    if (isNaN(value)) return `0 ${unidadeMedida}`;

    let valor = parseInt(value) + valorAjuste;
    if (valor < 1000)
      return `${valor} ${unidadeMedida}`

    valor = parseInt((valor / 1000));
    return `${Number.format(valor)} Mil ${unidadeMedida}`
  }

  return (
    <Grid sx={styles.container} style={{width: 'calc(50% - 8px)', marginLeft: '8px'}}>
      <Grid container item direction='row' alignItems='center'>
        <DateRange sx={styles.totaisIcon} />
        <Typography sx={styles.totaisTitle}>{titulo} por período do dia</Typography>
      </Grid>
      <Grid sx={styles.graficoContainer} style={{width: 280, alignSelf: 'center'}}>
        {loading && (
          <Grid sx={styles.loadingContainer}>
            <CircularProgress sx={styles.loading} />
          </Grid>
        )}
        <Pie
          label={titulo}
          ref={periodoRef}
          data={{
            datasets: [{
              labels: ['Manhã', 'Tarde', 'Noite', 'Madrugada'],
              ...periodoConfig
            }],
          }}
          height={280}
          options={{
            tooltips: {enabled: false},
            hover: {mode: null},
            legend: {display: false},
            plugins: {
              datalabels: {
                display: true,
                clamp: true,
                color: '#000',
                font: {
                  size: 16,
                  family: 'Poppins',
                  weight: '600'
                },
                textStrokeColor: '#fff',
                textStrokeWidth: 3,
                formatter: (value) => `${Number.safeParseInt(Number.getPorcentagem(value, valorTotal))} %`
              },
            }
          }}
        />
      </Grid>
      <Grid container item direction='row' justifyContent='center' spacing={3}>
        <Grid item>
          <FiberManualRecord style={{width: 18, height: 18, color: '#007BE166'}} />
          <Typography sx={styles.periodoDiaText}>Manhã</Typography>
          <Typography sx={styles.periodoDiaValue}>{formatPeriodo(1)}</Typography>
        </Grid>
        <Grid item>
          <FiberManualRecord style={{width: 18, height: 18, color: '#007BE1'}} />
          <Typography sx={styles.periodoDiaText}>Tarde</Typography>
          <Typography sx={styles.periodoDiaValue}>{formatPeriodo(2)}</Typography>
        </Grid>
        <Grid item>
          <FiberManualRecord style={{width: 18, height: 18, color: '#263238'}} />
          <Typography sx={styles.periodoDiaText}>Noite</Typography>
          <Typography sx={styles.periodoDiaValue}>{formatPeriodo(3)}</Typography>
        </Grid>
        <Grid item>
          <FiberManualRecord style={{width: 18, height: 18, color: '#26323829'}} />
          <Typography sx={styles.periodoDiaText}>Madrugada</Typography>
          <Typography sx={styles.periodoDiaValue}>{formatPeriodo(0)}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

const styles = {
  loading: {
    width: '30px !important',
    height: '30px !important',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '24px',
    borderRadius: '4px',
    background: '#fff',
    boxShadow: '0px 2px 12px 0px #0000001A',
    marginBottom: '16px',
  },
  totaisIcon: {
    color: '#031663',
    height: '20px',
    width: '20px',
    margin: '0 7px',
  },
  totaisTitle: {
    color: '#031663',
    fontSize: '18px',
    fontFamily: 'Poppins',
    fontWeight: '500',
  },
  graficoContainer: {
    position: 'relative',
    padding: '20px 0 10px 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  periodoDiaText: {
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '18px',
    color: '#263238',
  },
  periodoDiaValue: {
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '12px',
    color: '#263238',
  },
  loadingContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff6',
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
}

export default GraficoPeriodoDia;