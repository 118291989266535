import React, {useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import ReactToPrint from 'react-to-print';
import {Grid, Paper} from '@mui/material';
import {PrintRounded} from '@mui/icons-material';
import ImagensCampanha from './imagens';
import {RoundTooltipButton} from '../../components/mui-button';
import Data from '../../utils/data';
import ImpressaoImagensCampanha from './impressao-imagens';
import PeriodosHeadDashboard from '../../components/periodos';

const pageStyle = `
  @page { 
    size: A4; 
    margin: 0mm 5mm 0mm 5mm;
  }

  @media print {
    .chartjs-size-monitor{
      position: fixed !important;
    }
    html, body, #root {
      width: 297mm;
      height: 420mm;
      background: #fff !important;
    }
  }

  @media all {
    .impressao-motorista-container-first {
      min-height: 800px !important;
    }
    .impressao-motorista-container {
      min-height: 800px !important;
      padding-top: 300px !important;
    }
    .imagem-container-impressao {
      height: 190px !important;
    }
    .page-footer {
      display: block !important;
    }
    .report-header {
      display: block !important
    }
    div {
      -webkit-print-color-adjust: exact; 
    },
  }
`;

const CheckInCheckOut = () => {
  const {campanhaSelected, cidadeSelected} = useSelector(
    (store) => store.Toolbar,
  );
  const {periodoSelecionado, isPorPeriodos} = useSelector(
    (store) => store.Periodos,
  );

  const componentRef = useRef();

  const [loading, setLoading] = useState(false);

  const {periodos} = Data.getPeriodosCampanha(
    campanhaSelected,
    isPorPeriodos,
    periodoSelecionado,
  );

  return (
    <Grid id="page-container" container>
      <ReactToPrint
        pageStyle={pageStyle}
        documentTitle={`KM Mídia - Imagens da campanha - ${campanhaSelected?.anunciante?.nomeFantasia}`}
        onBeforePrint={() => setLoading(false)}
        onBeforeGetContent={() => setLoading(true)}
        trigger={() => (
          <RoundTooltipButton
            size="small"
            id="icon-toolbar"
            title={'Imprimir'}
            icon={<PrintRounded />}
            loading={loading}
            loadingColor="grey"
          />
        )}
        content={() => componentRef.current}
      />
      <PeriodosHeadDashboard />
      <Paper ref={componentRef} sx={styles.motoristasContainer}>
        <ImagensCampanha
          campanha={campanhaSelected}
          campanhaCidade={cidadeSelected}
        />
      </Paper>
      <div style={{overflow: 'hidden', height: 0}}>
        <Grid style={{background: '#fff'}} ref={componentRef}>
          <ImpressaoImagensCampanha
            campanha={campanhaSelected}
            campanhaCidade={cidadeSelected}
            periodos={periodos}
            periodoSelecionado={periodos[periodoSelecionado]}
          />
        </Grid>
      </div>
    </Grid>
  );
};

const styles = {
  motoristasContainer: {
    width: '100%',
    minHeight: 'calc(100vh - 115px)',
  },
};

export default CheckInCheckOut;
